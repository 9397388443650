<template>
  <v-card>
    <v-card-title>Role-Permission</v-card-title>
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-simple-table style="width: 100%">
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(role, key) in roles" :key="key" class="text-uppercase">
                {{ role.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(permission, permKey) in permissions" :key="permKey">
              <td v-for="(role, key) in roles" :key="key">
                <v-switch
                  value
                  :input-value="roleHasPermission(role, permission)"
                  :label="permission.name"
                  hide-details
                  @change="updatePermission(role.name, permission.name, $event)"
                ></v-switch>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-text> </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiExportVariant,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiCalendarBlankOutline,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import axios from '@axios'
import store from '@/store'
import useEmployees from '../employees/useEmployees'

export default {
  setup() {
    const { roles, getRoles, permissions, getPermissions } = useEmployees()

    onMounted(() => {
      getRoles()
      getPermissions()
    })

    const updatePermission = (role, permission, e) => {
      axios.post('/users/update-role-permission', { role, permission, status: e }).then(() => {
        axios.get('/current-user').then(response => {
          const user = response.data

          localStorage.setItem('userData', JSON.stringify(user))
          store.commit('auth/setUserCan', user.roles)
        })
      })
    }

    const roleHasPermission = (role, permission) => role.permissions.find(perm => perm.name === permission.name)

    return {
      roles,
      getRoles,

      permissions,
      getPermissions,

      updatePermission,
      roleHasPermission,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPlus,
        mdiExportVariant,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiCalendarBlankOutline,
      },
    }
  },
}
</script>
